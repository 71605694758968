import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Redirect } from "react-router";

import Colors from "../commons/Colors.js";
import * as ValidationUtils from "../commons/ValidationUtils.js";
import Input from "./components/Input.js";
import ValidatedInput from "./components/ValidatedInput.js";
import prodapLogo from "../commons/images/prodap-logo.png";
import { EnvironmentOutlined } from "@ant-design/icons";

export default function FormStart({
    setContactEmail,
    setSelectedForm,
    selectedForm,
}) {
    const [formData, setFormData] = useState({
        contactEmail: "",
        selectedForm: "",
    });

    const [validEmail, setValidEmail] = useState(true);
    const [validSelectedForm, setValidSelectedForm] = useState(true);
    const [redirectToTechnologyForm, setRedirectToTechnologyForm] =
        useState(false);
    const [redirectToSTPForm, setRedirectToSTPForm] = useState(false);

    const handleSubmit = (event) => {
        const isEmailValid = ValidationUtils.ValidateRequiredData(
            formData.contactEmail,
            "email"
        );
        const isSelectedFormValid = ValidationUtils.ValidateRequiredData(
            formData.selectedForm
        );

        setValidEmail(isEmailValid);
        setValidSelectedForm(isSelectedFormValid);

        if (isEmailValid && isSelectedFormValid) {
            setContactEmail(formData.contactEmail);
            setSelectedForm(formData.selectedForm);

            if (formData.selectedForm === "Cadastro Cliente Software")
                setRedirectToTechnologyForm(true);
            // if (formData.selectedForm === "Cadastro Cliente STP")
            //     setRedirectToSTPForm(true);
        }

        event.preventDefault();
    };

    return (
        <Form id="formIntroduction" onSubmit={handleSubmit}>
            {redirectToTechnologyForm ? (
                <Redirect to="/TechnologyForm" />
            ) : null}
            {redirectToSTPForm ? <Redirect to="/STPForm" /> : null}
            <Header>
                <View>
                    <Image src={prodapLogo} />
                    <h1>CADASTRO DE CLIENTE PRODAP</h1>
                    <p>
                        Formulário de cadastro de novos clientes/fazendas prodap
                        e atualização de cadastros existentes. <br />
                        Você receberá um e-mail confirmando o cadastro num prazo
                        máximo de 24 horas.
                    </p>
                </View>
            </Header>

            <ValidatedInput
                name="contactEmail"
                title="E-mail"
                inputType="email"
                placeholder="EX: exemplo@prodap.com.br"
                required={true}
                valid={validEmail}
                setValidation={setValidEmail}
                setFormData={setFormData}
            />

            <Input
                name="selectedForm"
                title="Cadastro desejado"
                inputType="dropDown"
                values={["Cadastro Cliente Software"]}
                required={true}
                valid={validSelectedForm}
                setValidation={setValidSelectedForm}
                setFormData={setFormData}
            />

            <SubmitButton>AVANÇAR</SubmitButton>
        </Form>
    );
}

const Image = styled.img`
    width: 50%;
    align-self: center;
`;

const SubmitButton = styled.button`
    background-color: ${Colors.leafyGreen};
    border: 0px;
    border-radius: 50px;
    color: white;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    align-self: flex-end;
    outline: none;

    &:hover {
        background-color: ${Colors.bluishGreen};
        cursor: pointer;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;

    padding: 10%;
    padding-top: 12px;
    padding-bottom: 12px;

    @media (max-width: 860px) {
        padding-left: 1.5%;
        padding-right: 1.5%;
    }

    background-color: ${Colors.darkBlue};
    height: 100vh;
`;

const Header = styled.div`
    background-color: ${Colors.lightBlue};
    border: 0px;
    border-radius: 8px;

    margin-bottom: 12px;

    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
`;

const View = styled.div`
    display: flex;
    flex-direction: column;

    background-color: #fff;

    margin-top: 12px;

    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0px 0px 5px 5px;
`;
