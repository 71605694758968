import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import moment from "moment";

import * as SydleAPI from "../commons/SydleAPI";

const PrivateRoute = ({ children, ...props }) => {
    const userToken = localStorage.getItem("contractsToken");
    const location = useLocation();
    const HOURS_TO_REFRESH_TOKEN = 96;

    const isValidToken = validatesToken(userToken);
    if (isValidToken) {
        return children ? children : props.render();
    } else {
        localStorage.clear();
        return (
            <Redirect
                to={{
                    pathname: "/login",
                    state: { from: location },
                }}
            />
        );
    }

    function validatesToken(userToken) {
        try {
            userToken = JSON.parse(userToken);

            if (
                userToken &&
                userToken.accessToken &&
                userToken.accessToken.payload
            ) {
                const currentDate = Date.now().valueOf() / 1000;
                const tokenExpirationDate = userToken.accessToken.payload.exp;

                const hoursToExpirate = moment
                    .duration(
                        (tokenExpirationDate - currentDate) * 1000,
                        "milliseconds"
                    )
                    .asHours();

                const isTokenExpirated = hoursToExpirate < 0;
                if (isTokenExpirated) {
                    return false;
                }
                if (hoursToExpirate < HOURS_TO_REFRESH_TOKEN) {
                    refreshToken(userToken.accessToken.token);
                }
                return true;
            }
            return false;
        } catch (err) {
            return false;
        }
    }

    async function refreshToken(token) {
        const response = await SydleAPI.renewToken(token);

        if (response.accessToken) {
            localStorage.setItem("contractsToken", JSON.stringify(response));
        }
    }
};

export default PrivateRoute;
