import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { WarningOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import Colors from "../../commons/Colors.js";
import "antd/dist/antd.css";
import * as ValidationUtils from "../../commons/ValidationUtils.js";

export default function AddressInput({
    name,
    form,
    title,
    description,
    required = false,
    valid = true,
    uppercase = false,
    setValidation,
    setFormData,
}) {
    const [loading, setLoading] = useState(false);

    const [cepValue, setCepValue] = useState("");
    const [uf, setUf] = useState("");
    const [city, setCity] = useState("");
    const [neighborhood, setNeighborhood] = useState("");
    const [street, setStreet] = useState("");
    const [number, setNumber] = useState("");

    const [validCep, setValidCep] = useState(true);

    useEffect(() => {
        setFormData((actualState) => {
            let updated = Object.assign(actualState);
            updated[name] = {
                cep: cepValue,
                state: uf,
                city: city,
                neighborhood: neighborhood,
                street: street,
                number: number,
            };

            return updated;
        });
    });

    const UFs = {
        AC: "Acre",
        AL: "Alagoas",
        AP: "Amapá",
        AM: "Amazonas",
        BA: "Bahia",
        CE: "Ceará",
        DF: "Distrito Federal",
        ES: "Espírito Santo",
        GO: "Goiás",
        MA: "Maranhão",
        MT: "Mato Grosso",
        MS: "Mato Grosso do Sul",
        MG: "Minas Gerais",
        PA: "Pará",
        PB: "Paraíba",
        PR: "Paraná",
        PE: "Pernambuco",
        PI: "Piauí",
        RJ: "Rio de Janeiro",
        RN: "Rio Grande do Norte",
        RS: "Rio Grande do Sul",
        RO: "Rondônia",
        RR: "Roraima",
        SC: "Santa Catarina",
        SP: "São Paulo",
        SE: "Sergipe",
        TO: "Tocantins",
    };

    const searchAddressByCep = async () => {
        setLoading(true);
        setValidation(true);

        const address = await ValidationUtils.searchCep(cepValue);
        if (address.valid) {
            setAddressInfo(address);
        } else {
            setValidCep(false);
            setValidation(false);

            setTimeout(() => setLoading(false), 100);
            return;
        }
    };

    const setAddressInfo = (address) => {
        if (address.state) {
            if (address.state.length === 2) {
                setUf(UFs[address.state]);
            } else {
                setUf(address.state);
            }
        } else {
            setUf("");
        }

        address.city ? setCity(address.city) : setCity("");

        address.neighborhood
            ? setNeighborhood(address.neighborhood)
            : setNeighborhood("");

        address.street ? setStreet(address.street) : setStreet("");

        setTimeout(() => setLoading(false), 200);

        setValidCep(true);
        return;
    };

    const validateAndFormatCepInput = (newValue) => {
        if (newValue) {
            const isValid = ValidationUtils.ValidateInput(newValue, "cep");

            if (isValid) {
                const formattedInput = ValidationUtils.FormatInput(
                    newValue,
                    "cep"
                );
                setCepValue(formattedInput);
            }
            return;
        } else {
            setCepValue("");
            return;
        }
    };

    const handleOnBlur = async (finalValue) => {
        if (!required && finalValue === "") setValidation(true);
        else {
            const valid = (await ValidationUtils.searchCep(finalValue)).valid;
            setValidCep(valid);
            setValidation(valid);
        }
    };

    const renderInvalid = () => {
        if (!validCep) {
            return (
                <Invalid>
                    <WarningOutlined style={{ color: "white" }} />
                    <InvalidText>
                        O CEP inserido é inválido. Por favor, revise as
                        informações digitadas
                    </InvalidText>
                </Invalid>
            );
        }
        return (
            <Invalid>
                <WarningOutlined style={{ color: "white" }} />
                <InvalidText>
                    Preencha os dados de endereço corretamente
                </InvalidText>
            </Invalid>
        );
    };

    return (
        <div>
            <Container>
                <Spin spinning={loading} tip="Carregando...">
                    <InputContainer>
                        <h3>{title}</h3>
                        <p>{description ? description : null}</p>

                        <Section
                            onFocus={() =>
                                required ? setValidation(true) : null
                            }
                        >
                            <h4>CEP</h4>
                            <InputField>
                                <TextInput
                                    placeholder="00000-000"
                                    name="cep"
                                    onChange={(state) =>
                                        validateAndFormatCepInput(
                                            state.target.value
                                        )
                                    }
                                    value={cepValue}
                                    onBlur={searchAddressByCep}
                                />
                                {required ? <Required>*</Required> : null}
                            </InputField>
                        </Section>

                        <Section
                            onFocus={() =>
                                required ? setValidation(true) : null
                            }
                        >
                            <h4>Estado (UF)</h4>
                            <InputField>
                                <DropDown
                                    name="state"
                                    value={uf}
                                    onChange={(event) =>
                                        setUf(event.target.value)
                                    }
                                >
                                    <DropDownPlaceHolder key="" value="">
                                        (vazio)
                                    </DropDownPlaceHolder>
                                    {Object.values(UFs).map((uf) => {
                                        return (
                                            <option key={uf} value={uf}>
                                                {uf}
                                            </option>
                                        );
                                    })}
                                </DropDown>
                                {required ? <Required>*</Required> : null}
                            </InputField>
                        </Section>

                        <Section
                            onFocus={() =>
                                required ? setValidation(true) : null
                            }
                        >
                            <h4>Cidade</h4>
                            <InputField>
                                <TextInput
                                    placeholder="Ex: Belo Horizonte"
                                    name="city"
                                    value={city}
                                    onChange={(event) =>
                                        setCity(event.target.value)
                                    }
                                    uppercase={uppercase}
                                />
                                {required ? <Required>*</Required> : null}
                            </InputField>
                        </Section>

                        <Section
                            onFocus={() =>
                                required ? setValidation(true) : null
                            }
                        >
                            <h4>Bairro</h4>
                            <InputField>
                                <TextInput
                                    placeholder="Ex: Centro"
                                    name="neighborhood"
                                    value={neighborhood}
                                    onChange={(event) =>
                                        setNeighborhood(event.target.value)
                                    }
                                    uppercase={uppercase}
                                />
                                {required ? <Required>*</Required> : null}
                            </InputField>
                        </Section>

                        <Section
                            onFocus={() =>
                                required ? setValidation(true) : null
                            }
                        >
                            <h4>Rua / Avenida / Rodovia / Estrada</h4>
                            <InputField>
                                <TextInput
                                    placeholder="Ex: AV Pedro II"
                                    name="street"
                                    value={street}
                                    onChange={(event) =>
                                        setStreet(event.target.value)
                                    }
                                    uppercase={uppercase}
                                />
                                {required ? <Required>*</Required> : null}
                            </InputField>
                        </Section>

                        <Section
                            onFocus={() =>
                                required ? setValidation(true) : null
                            }
                        >
                            <h4>Número</h4>
                            <InputField>
                                <TextInput
                                    placeholder="Ex: 123 OU S/N"
                                    name="number"
                                    form={form}
                                    value={number}
                                    onChange={(event) =>
                                        setNumber(event.target.value)
                                    }
                                    uppercase={uppercase}
                                />
                                {required ? <Required>*</Required> : null}
                            </InputField>
                        </Section>
                    </InputContainer>
                </Spin>
                {!valid ? renderInvalid() : null}
            </Container>
        </div>
    );
}

const DropDownPlaceHolder = styled.option`
    color: grey;
`;

const Section = styled.div`
    margin-top: 15px;
    border-left: 1px solid ${Colors.lightBlueGrey};
    padding-left: 10px;
`;

const Invalid = styled.div`
    background-color: ${Colors.strawberry};
    margin-top: 10px;
    padding-left: 20px;
    border-radius: 0px 0px 8px 8px;

    @keyframes slide {
        from {
            height: 0px;
            font-size: 50%;
        }
        to {
            height: 40px;
            font-size: 100%;
        }
    }

    display: flex;
    align-items: center;

    animation-name: slide;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
`;

const InvalidText = styled.p`
    color: white;
    margin: 0px;
    margin-left: 10px;

    @media (max-width: 860px) {
        font-size: 90%;
    }
`;

const InputContainer = styled.div`
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
`;

const Container = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;

    border: 0px;
    border-radius: 8px;

    margin-bottom: 12px;
    align-content: stretch;

    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
`;

const InputField = styled.div`
    display: flex;
    flex-direction: row;
`;

const TextInput = styled.input`
    padding: 5px;
    padding-top: 0px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color: ${Colors.lightBlue};

    outline: none;

    width: 30%;

    @media (max-width: 860px) {
        width: 70%;
    }
`;

const Required = styled.p`
    color: #f00;
    margin: 0px;
    padding-left: 10px;
`;

const DropDown = styled.select`
    border-radius: 5px;
    padding: 5px;
    outline: none;
    border: 1px solid #dadce0;
`;
