import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { WarningOutlined } from "@ant-design/icons";
import "react-phone-number-input/style.css";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";

import Colors from "../../commons/Colors.js";
import * as ValidationUtils from "../../commons/ValidationUtils.js";

export default function Input({
    name,
    form,
    title,
    inputType,
    description,
    placeholder,
    required = false,
    valid = true,
    uppercase = false,
    setValidation,
    setFormData,
}) {
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        setFormData((actualState) => {
            let updated = Object.assign(actualState);

            inputType === "phoneNumber"
                ? (updated[name] = formatPhoneNumberIntl(inputValue))
                : (updated[name] = inputValue);

            return updated;
        });
    });

    const handleOnchange = (newValue, inputType) => {
        if (newValue) {
            const isValid = ValidationUtils.ValidateInput(newValue, inputType);

            if (isValid) {
                const formattedInput = ValidationUtils.FormatInput(
                    newValue,
                    inputType
                );
                uppercase
                    ? setInputValue(formattedInput.toUpperCase())
                    : setInputValue(formattedInput);
            }
            return;
        } else {
            setInputValue("");
            return;
        }
    };

    const handleOnBlur = (inputValue, inputType) => {
        if (!required && inputValue === "") setValidation(true);
        else
            setValidation(
                ValidationUtils.FinalValidation(inputValue, inputType)
            );
    };

    const renderInvalid = () => {
        const getName = () => {
            if (inputType === "cpf/cnpj") return "CPF / CNPJ";

            if (inputType === "phoneNumber") return "Número de Telefone";

            if (inputType === "email") return "endereço de email";
        };
        const getInvalidMessage = () => {
            if (required && inputValue === "") {
                return "O campo '" + title + "' é obrigatório";
            }

            if (inputValue !== "") {
                return "Insira um " + getName() + " válido";
            }
        };

        return (
            <Invalid>
                <WarningOutlined style={{ color: "white" }} />
                <InvalidText>{getInvalidMessage()}</InvalidText>
            </Invalid>
        );
    };

    const renderInput = () => {
        return inputType === "phoneNumber" ? (
            <InputField>
                <PhoneNumber
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={(state) => handleOnchange(state, inputType)}
                    onBlur={() => handleOnBlur(inputValue, inputType)}
                    defaultCountry="BR"
                    onFocus={() => setValidation(true)}
                />
                {required ? <Required>*</Required> : null}
            </InputField>
        ) : (
            <InputField>
                <TextInput
                    type="text"
                    placeholder={placeholder}
                    form={form}
                    id={name}
                    name={name}
                    onChange={(state) =>
                        handleOnchange(state.target.value, inputType)
                    }
                    value={inputValue}
                    onBlur={() => handleOnBlur(inputValue, inputType)}
                    onFocus={() => setValidation(true)}
                />
                {required ? <Required>*</Required> : null}
            </InputField>
        );
    };

    return (
        <Container>
            <InputContainer>
                <h3>{title}</h3>
                <p>{description ? description : null}</p>
                {renderInput()}
            </InputContainer>
            {!valid ? renderInvalid() : null}
        </Container>
    );
}

const PhoneNumber = styled(PhoneInput)`
    & > input {
        padding: 5px;
        padding-top: 10px;
        border-top: none !important ;
        border-left: none;
        border-right: none;
        border-bottom-color: ${Colors.lightBlue};

        outline: none;
    }
    width: 30%;

    @media (max-width: 860px) {
        width: 70%;
    }
`;

const Invalid = styled.div`
    background-color: ${Colors.strawberry};
    margin-top: 10px;
    padding-left: 20px;
    border-radius: 0px 0px 8px 8px;

    @keyframes slide {
        from {
            height: 0px;
            font-size: 0%;
        }
        to {
            height: 40px;
            font-size: 100%;
        }
    }

    display: flex;
    align-items: center;

    animation-name: slide;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
`;

const InvalidText = styled.p`
    color: white;
    margin: 0px;
    margin-left: 10px;

    @media (max-width: 860px) {
        font-size: 90%;
    }
`;

const Container = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;

    border: 0px;
    border-radius: 8px;

    margin-bottom: 12px;
    align-content: stretch;

    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
`;

const InputContainer = styled.div`
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
`;

const InputField = styled.div`
    display: flex;
    flex-direction: row;
`;

const TextInput = styled.input`
    padding: 5px;
    padding-top: 10px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color: ${Colors.lightBlue};

    outline: none;

    width: 30%;

    @media (max-width: 860px) {
        width: 70%;
    }
`;

const Required = styled.p`
    color: #f00;
    margin: 0px;
    padding-left: 10px;
`;
