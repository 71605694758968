import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, Button, message } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Redirect } from "react-router";

import Colors from "../commons/Colors.js";
import * as ValidationUtils from "../commons/ValidationUtils.js";
import Input from "./components/Input.js";
import TextWithDropDownInput from "./components/TextWithDropDownInput.js";
import ValidatedInput from "./components/ValidatedInput.js";
import AddressInput from "./components/AddressInput.js";
import TextWithCheckboxInput from "./components/TextWithCheckBoxInput.js";
import * as SydleAPI from "../commons/SydleAPI.js";

import prodapLogo from "../commons/images/prodap-logo.png";

export default function FormTechnology({ startInfo }) {
    const [formData, setFormData] = useState({
        startInfo: startInfo,
        socialReason: "",
        selectedProduct: "",
        cpfOrCnpj: "",
        propertyName: {
            prefix: "",
            name: "",
        },
        phoneNumber: "",
        whatsAppNumber: "",
        otherPhoneNumber: "",
        emailNF: "",
        emailBOL: "",
        addressNF: {
            cep: "",
            state: "",
            city: "",
            neighborhood: "",
            street: "",
            number: "",
        },
        stateRegistration: "ISENTO",
        consultantName: "",
        notes: "",
    });

    const [isRedirectModalVisible, setIsRedirectModalVisible] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [redirectToHome, setRedirectToHome] = useState(false);
    const [isSendingForm, setIsSendingForm] = useState(false);

    useEffect(() => {
        if (
            formData.startInfo.contactEmail === "" ||
            formData.startInfo.selectedForm !== "Cadastro Cliente Software"
        ) {
            setIsRedirectModalVisible(true);
            return;
        }
    });

    //const [valid, setValid] = useState(true);
    const [validSocialReason, setValidSocialReason] = useState(true);
    const [validSelectedProduct, setValidSelectedProduct] = useState(true);
    const [validCpfOrCnpj, setValidCpfOrCnpj] = useState(true);
    const [validPropertyName, setValidPropertyName] = useState(true);
    const [validPhoneNumber, setValidPhoneNumber] = useState(true);

    const [validWhatsappNumber, setValidWhatsAppNumber] = useState(true);
    const [validOtherPhoneNumber, setValidOtherPhoneNumber] = useState(true);
    const [validEmailNF, setValidEmailNF] = useState(true);
    const [validEmailBOL, setValidEmailBOL] = useState(true);
    const [validAddressNF, setValidAddressNF] = useState(true);
    const [validConsultantName, setValidConsultantName] = useState(true);

    const handleSubmit = (event) => {
        const validations = {
            socialReason: ValidationUtils.ValidateRequiredData(
                formData.socialReason
            ),
            selectedProduct: ValidationUtils.ValidateRequiredData(
                formData.selectedProduct
            ),
            cpfOrCnpj: ValidationUtils.ValidateRequiredData(
                formData.cpfOrCnpj,
                "cpf/cnpj"
            ),
            propertyName: ValidationUtils.ValidateComplexData(
                formData.propertyName
            ),
            phoneNumber: ValidationUtils.ValidateRequiredData(
                formData.phoneNumber,
                "phoneNumber"
            ),
            otherPhoneNumber: ValidationUtils.ValidateOptionalData(
                formData.otherPhoneNumber,
                "phoneNumber"
            ),
            emailNF: ValidationUtils.ValidateRequiredData(
                formData.emailNF,
                "email"
            ),
            emailBOL: ValidationUtils.ValidateRequiredData(
                formData.emailBOL,
                "email"
            ),
            addressNF: ValidationUtils.ValidateComplexData(formData.addressNF),
            consultantName: ValidationUtils.ValidateRequiredData(
                formData.consultantName
            ),
        };
        setValidSocialReason(validations.socialReason);
        setValidSelectedProduct(validations.selectedProduct);
        setValidCpfOrCnpj(validations.cpfOrCnpj);
        setValidPropertyName(validations.propertyName);
        setValidPhoneNumber(validations.phoneNumber);
        setValidOtherPhoneNumber(validations.otherPhoneNumber);
        setValidEmailNF(validations.emailNF);
        setValidEmailBOL(validations.emailBOL);
        setValidAddressNF(validations.addressNF);
        setValidConsultantName(validations.consultantName);

        if (Object.values(validations).every((item) => item === true)) {
            setIsConfirmModalVisible(true);
        } else {
            message.error("Um ou mais campos estão incorretos.");
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }

        event.preventDefault();
    };

    const sendForm = async () => {
        setIsSendingForm(true);

        const success = await SydleAPI.submitForm(
            formData,
            formData.startInfo.selectedForm
        );

        if (success) {
            message.success(
                "Cadastro efetuado! você será redirecionado para a tela inicial em 5 segundos"
            );

            setTimeout(() => setRedirectToHome(true), 5000);
        } else {
            setIsSendingForm(false);
            message.error(
                "Houve um erro ao processar o seu formulário, tente novamente mais tarde"
            );
        }
    };

    window.addEventListener("beforeunload", (event) => {
        event.returnValue =
            "Tem certeza que deseja sair? Suas alterações serão perdidas";
    });

    return (
        <Form id="tech" onSubmit={(event) => handleSubmit(event)}>
            {redirectToHome ? <Redirect to="/" /> : null}

            <Modal
                title={
                    <div>
                        <CloseCircleOutlined
                            style={{
                                color: Colors.strawberry,
                                marginRight: 10,
                            }}
                        />
                        Sessão Inválida
                    </div>
                }
                visible={isRedirectModalVisible}
                closable={false}
                footer={[
                    <Button
                        key="back"
                        onClick={() => setRedirectToHome(true)}
                        type="primary"
                    >
                        Voltar
                    </Button>,
                ]}
            >
                <p>
                    Sessão expirou ou inválida, você será redirecionado para a
                    tela inicial
                </p>
            </Modal>

            <Modal
                title="Enviar Cadastro?"
                visible={isConfirmModalVisible}
                onOk={null}
                closable={false}
                footer={[
                    <Button
                        key="cancel"
                        onClick={() => setIsConfirmModalVisible(false)}
                        disabled={isSendingForm}
                    >
                        Cancelar
                    </Button>,
                    <Button
                        key="ok"
                        onClick={sendForm}
                        loading={isSendingForm}
                        type="primary"
                    >
                        Ok
                    </Button>,
                ]}
            >
                <p>
                    Você confirma o envio do formulário? Clique em
                    &apos;Ok&apos; para enviar, e em &apos;Cancelar&apos; para
                    voltar à edição
                </p>
            </Modal>

            <Header>
                <View>
                    <Image src={prodapLogo} />
                    <h1>Cadastro Cliente Prodap</h1>
                </View>
            </Header>

            <Input
                name="socialReason"
                title="Nome (Razão Social)"
                form="tech"
                placeholder="EX: PRODAP LTDA"
                required={true}
                valid={validSocialReason}
                setValidation={setValidSocialReason}
                setFormData={setFormData}
                uppercase
            />

            <Input
                name="selectedProduct"
                title="Produto Desejado"
                form="tech"
                inputType="dropDown"
                values={[
                    "Prodap Views Prime",
                    "Prodap Views Master",
                    "Prodap Smartmilk",
                    "Prodap Fabs",
                    "Prodap Views Smart",
                ]}
                required={true}
                valid={validSelectedProduct}
                setValidation={setValidSelectedProduct}
                setFormData={setFormData}
            />

            <ValidatedInput
                name="cpfOrCnpj"
                form="tech"
                title="CPF / CNPJ"
                inputType="cpf/cnpj"
                placeholder="Insira o seu CPF ou CNPJ"
                required={true}
                valid={validCpfOrCnpj}
                setValidation={setValidCpfOrCnpj}
                setFormData={setFormData}
                uppercase
            />

            <TextWithDropDownInput
                name="propertyName"
                title="Nome da Fazenda/Cooperativa/Fábrica"
                description="Selecione o tipo de propriedade e digite o nome da mesma"
                form="tech"
                placeholder="Insira aqui o nome da propriedade"
                values={["FAZ", "COOP", "FAB"]}
                required={true}
                valid={validPropertyName}
                setValidation={setValidPropertyName}
                setFormData={setFormData}
                uppercase
            />

            <ValidatedInput
                name="phoneNumber"
                form="tech"
                title="Número de Telefone"
                inputType="phoneNumber"
                placeholder="EX: (31)9876-5432"
                required={true}
                valid={validPhoneNumber}
                setValidation={setValidPhoneNumber}
                setFormData={setFormData}
            />

            <ValidatedInput
                name="whatsAppNumber"
                form="tech"
                title="Contato WhatsApp"
                inputType="phoneNumber"
                placeholder="EX: (31)9876-5432"
                valid={validWhatsappNumber}
                setValidation={setValidWhatsAppNumber}
                setFormData={setFormData}
            />

            <ValidatedInput
                name="otherPhoneNumber"
                form="tech"
                title="Outros Contatos Financeiros"
                inputType="phoneNumber"
                placeholder="EX: (31)9876-5432"
                valid={validOtherPhoneNumber}
                setValidation={setValidOtherPhoneNumber}
                setFormData={setFormData}
            />

            <ValidatedInput
                name="emailNF"
                form="tech"
                title="E-mail Nota Fiscal"
                inputType="email"
                placeholder="EX: nfe@prodap.com.br"
                required={true}
                valid={validEmailNF}
                setValidation={setValidEmailNF}
                setFormData={setFormData}
            />

            <ValidatedInput
                name="emailBOL"
                form="tech"
                title="E-mail Boleto"
                inputType="email"
                placeholder="EX: nfe@prodap.com.br"
                required={true}
                valid={validEmailBOL}
                setValidation={setValidEmailBOL}
                setFormData={setFormData}
            />

            <AddressInput
                name="addressNF"
                form="tech"
                title="Endereço de Nota Fiscal"
                description="Insira aqui seu endereço. Caso não haja número no local, insira 'S/N' no campo."
                required={true}
                valid={validAddressNF}
                setValidation={setValidAddressNF}
                setFormData={setFormData}
                uppercase
            />

            <Input
                name="notes"
                title="Observações"
                form="tech"
                placeholder="Digite aqui observações do cadastro"
                setFormData={setFormData}
            />

            <Input
                name="consultantName"
                title="Nome Do Consultor"
                form="tech"
                placeholder="Nome Do Consultor"
                setFormData={setFormData}
            />

            {/* <TextWithCheckboxInput
                name="stateRegistration"
                title="Inscrição Estadual"
                form="tech"
                placeholder="Insira aqui sua INS. Estadual"
                checkBoxText="ISENTO"
                required={true}
                valid={validStateRegistration}
                setValidation={setValidStateRegistration}
                setFormData={setFormData}
            /> */}

            <Input
                name="consultantName"
                title="Nome Do consultor"
                form="tech"
                placeholder="Nome Do consultor"
                required={true}
                valid={validConsultantName}
                setValidation={setValidConsultantName}
                setFormData={setFormData}
            />

            <SubmitButton>Finalizar</SubmitButton>
        </Form>
    );
}

const Image = styled.img`
    width: 50%;
    align-self: center;
`;

const SubmitButton = styled.button`
    background-color: ${Colors.leafyGreen};
    border: 0px;
    border-radius: 50px;
    color: white;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    align-self: flex-end;
    outline: none;

    &:hover {
        background-color: ${Colors.bluishGreen};
        cursor: pointer;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;

    padding: 10%;
    padding-top: 12px;
    padding-bottom: 12px;

    @media (max-width: 860px) {
        padding-left: 1.5%;
        padding-right: 1.5%;
    }

    background-color: ${Colors.darkBlue};
`;

const Header = styled.div`
    background-color: ${Colors.lightBlue};
    border: 0px;
    border-radius: 8px;

    margin-bottom: 12px;

    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
`;

const View = styled.div`
    display: flex;
    flex-direction: column;

    background-color: #fff;

    margin-top: 12px;

    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0px 0px 5px 5px;
`;
