const BASE_URL = {
    PRD: "https://prodap.sydle.one/api/1/main",
    DEV: "https://prodap-dev.sydle.one/api/1/main",
};

export const getBaseURL = () => {
    if (process.env.NODE_ENV === "production") return BASE_URL.PRD;
    else return BASE_URL.DEV;
};

export const getToken = () => {
    let userToken = localStorage.getItem("contractsToken");

    if (userToken) {
        userToken = JSON.parse(userToken);
        return userToken.accessToken ? userToken.accessToken.token : null;
    }
    return null;
};

export async function submitForm(data, selectedForm) {
    let formIdentifier;

    if (selectedForm === "Cadastro Cliente STP") formIdentifier = "STPForm";
    if (selectedForm === "Cadastro Cliente Software")
        formIdentifier = "technologyForm";

    let success;
    await fetch(
        `${getBaseURL()}/contratos/${formIdentifier}/handleFormSubmit`,
        {
            method: "POST",
            headers: {
                Authorization: `Bearer ${getToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }
    )
        .then(async (response) => {
            const responseBody = await response.json();
            success = responseBody.done || false;
        })
        .catch((err) => {
            console.error("Erro: ", JSON.stringify(err));
            success = false;
        });

    return success;
}

export async function signIn(login, password) {
    const value = btoa(`${login}:${password}`);

    return fetch(`${getBaseURL()}/sys/auth/signIn`, {
        method: "GET",
        headers: {
            Authorization: `Basic ${value}`,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.error("Erro: " + JSON.stringify(error));
            throw error;
        });
}

export async function renewToken(token) {
    return fetch(`${getBaseURL()}/sys/auth/renewToken`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.error("Erro: " + JSON.stringify(error));
            throw error;
        });
}

export async function getTechnologyForms() {
    return fetch(`${getBaseURL()}/contratos/technologyForm/getForms`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.error("Erro: " + JSON.stringify(error));
            throw error;
        });
}

export async function getSTPForms() {
    return fetch(`${getBaseURL()}/contratos/STPForm/getForms`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.error("Erro: " + JSON.stringify(error));
            throw error;
        });
}

export async function getForm(id, form) {
    const formIdentifier = () => {
        if (form == "tech") return "technologyForm";
        if (form == "STP") return "STPForm";
    };

    return fetch(`${getBaseURL()}/contratos/${formIdentifier()}/getForm`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ _id: id }),
    })
        .then((response) => response.json())
        .catch((error) => {
            console.error("Erro: " + JSON.stringify(error));
            throw error;
        });
}
