const Colors = {
    darkBlue: "rgb(6, 86, 135)",
    lightBlue: "rgb(89, 197, 243)",
    babyBlue: "rgb(144, 232, 244)",
    lightishGreen: "#68e182",
    hospitalGreen: "#a3e9a4",
    leafyGreen: "#57bd41",
    softGreen: "#78c47b",
    bluishGreen: "#16a074",
    lightBlueGrey: "#d1d1d6",
    lightBlueGreyTwo: "#c7c7cc",
    veryLightPink: "#f1f1f1",
    brownishGrey: "#666666",
    black: "#333333",
    white: "#ffffff",
    grey: "#dadada",

    orange: "#e57509",
    orangeTwo: "#e67508",
    dustyOrange: "#f18d3a",
    sienna: "#b15717",
    deepSeaBlue: "#005186",
    marine: "#0a2658",
    peacockBlue: "#0053a0",
    grass: "#4da32f",
    paleLilac: "#e5e5ea",
    blueGrey: "#8e8e93",
    strawberry: "#eb394b",
    lightViolet: "#cec5e6",
    iris: "#775bbc",
    powderBlue: "#c1ddf8",
    softBlue: "#5aa5ef",
    manilla: "#fbe08f",
    butterscotch: "#f7c244",
    mediumBlue: "#2e77b8",
    lightBlueGreyThree: "#c6cbd4",
    lightishBlue: "#4b74ff",
    lipstick: "#ca182a",
    macaroniAndCheese: "#f1b63a",
    orange15: "rgba(229, 117, 9, 0.15)",
};

export default Colors;
