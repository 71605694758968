import React, { useState } from "react";
import styled from "styled-components";
import { Menu, Dropdown, message } from "antd";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import { Version } from "../version";
import { Redirect } from "react-router";

export default function UserArea() {
    const [redirectToLogin, setRedirectToLogin] = useState(false);

    const getToken = () => {
        const token = localStorage.getItem("contractsToken");
        return JSON.parse(token);
    };

    const logout = () => {
        localStorage.clear();

        message.info("Deslogado com sucesso! Redirecionando...");

        setRedirectToLogin(true);
    };

    const [userToken, setUserToken] = useState(getToken());

    const menu = (
        <Menu>
            <Menu.Item key="version" disabled>
                Versão {Version}
            </Menu.Item>
            <Menu.Item key="logout">
                <LogoutButton onClick={logout}>
                    <LogoutIcon />
                    Sair
                </LogoutButton>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            {redirectToLogin && <Redirect to={{ pathname: "/login" }} />}
            <Dropdown overlay={menu}>
                <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                >
                    Olá, {userToken.name}!
                    <Arrow />
                </a>
            </Dropdown>
        </>
    );
}

const Arrow = styled(DownOutlined)`
    margin-left: 10px;
`;

const LogoutButton = styled.button`
    display: flex;
    align-items: center;

    background: none;
    outline: none;
    border: none;
`;

const LogoutIcon = styled(LogoutOutlined)`
    margin-right: 20px;
`;
