import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { WarningOutlined } from "@ant-design/icons";
import Colors from "../../commons/Colors.js";

export default function TextWithCheckBoxInput({
    name,
    form,
    title,
    description,
    placeholder,
    required = false,
    valid = true,
    setValidation,
    setFormData,
    checkBoxText,
}) {
    const [isChecked, setIsChecked] = useState(false);
    const [text, setText] = useState("");

    useEffect(() => {
        setFormData((actualState) => {
            let updated = Object.assign(actualState);
            updated[name] = text;

            return updated;
        });
    });

    const renderInvalid = () => {
        return (
            <Invalid>
                <WarningOutlined style={{ color: "white" }} />
                <InvalidText>
                    O campo &quot;{title}&quot; é obrigatório
                </InvalidText>
            </Invalid>
        );
    };

    const renderTextInput = () => {
        return (
            <InputField>
                <TextInput
                    placeholder={placeholder}
                    form={form}
                    id="text"
                    name="text"
                    value={text}
                    onChange={(event) => setText(event.target.value)}
                />
                {required ? <Required>*</Required> : null}
            </InputField>
        );
    };

    return (
        <div>
            <Container onFocus={() => (required ? setValidation(true) : null)}>
                <InputContainer>
                    <h3>{title}</h3>
                    <p>{description ? description : null}</p>
                    {!isChecked ? renderTextInput() : null}
                    <InputField>
                        <CheckBox
                            type="checkbox"
                            id={"checkbox"}
                            name={"checkbox"}
                            value={checkBoxText}
                            onChange={(event) => {
                                setIsChecked(event.target.checked);

                                event.target.checked
                                    ? setText(event.target.value)
                                    : setText("");
                            }}
                        />
                        <Text>{checkBoxText}</Text>
                    </InputField>
                </InputContainer>
                {!valid ? renderInvalid() : null}
            </Container>
        </div>
    );
}

const CheckBox = styled.input`
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    align-self: center;
`;

const Text = styled.p`
    align-self: center;
    margin: 0px;
`;

const Invalid = styled.div`
    background-color: ${Colors.strawberry};
    margin-top: 10px;
    padding-left: 20px;
    border-radius: 0px 0px 8px 8px;

    @keyframes slide {
        from {
            height: 0px;
            font-size: 50%;
        }
        to {
            height: 40px;
            font-size: 100%;
        }
    }

    display: flex;
    align-items: center;

    animation-name: slide;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
`;

const InvalidText = styled.p`
    color: white;
    margin: 0px;
    margin-left: 10px;

    @media (max-width: 860px) {
        font-size: 90%;
    }
`;

const InputContainer = styled.div`
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
`;

const Container = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;

    border: 0px;
    border-radius: 8px;

    margin-bottom: 12px;
    align-content: stretch;

    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
`;

const InputField = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
`;

const TextInput = styled.input`
    margin-left: 10px;
    padding: 5px;
    padding-top: 10px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color: ${Colors.lightBlue};

    outline: none;

    width: 30%;

    @media (max-width: 860px) {
        width: 70%;
    }
`;

const Required = styled.p`
    color: #f00;
    margin: 0px;
    padding-left: 10px;
`;
