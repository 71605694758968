import { cpf, cnpj } from "cpf-cnpj-validator";
import cep from "cep-promise";
import { isPossiblePhoneNumber } from "react-phone-number-input";

export function ValidateInput(input, validationType) {
    if (validationType === "cpf/cnpj") return validateAsCpfOrCnpj(input);

    if (validationType === "cep") return validateAsCep(input);

    return true;
}

export function FormatInput(input, inputType) {
    if (inputType === "cpf/cnpj") {
        const numbers = getNumbers(input);

        if (numbers.length <= 11) return formatAsCpf(numbers);
        else return formatAsCnpj(numbers);
    }

    if (inputType === "cep") {
        const numbers = getNumbers(input);

        return formatAsCep(numbers);
    }

    return input;
}

export function FinalValidation(input, inputType) {
    if (inputType === "cpf/cnpj") {
        const numbers = getNumbers(input);

        if (numbers.length <= 11) {
            return cpf.isValid(numbers.join(""));
        } else {
            return cnpj.isValid(numbers.join(""));
        }
    }

    if (inputType === "phoneNumber") {
        return isPossiblePhoneNumber(input);
    }

    if (inputType === "email") {
        return validateAsEmail(input);
    }
}

export async function searchCep(input) {
    try {
        let search = await cep(input);
        search.valid = true;

        return search;
    } catch (e) {
        return { valid: false };
    }
}

export function ValidateRequiredData(input, inputType) {
    if (input === "" || input === null) return false;

    if (inputType) return FinalValidation(input, inputType);

    return true;
}

export function ValidateComplexData(input, inputType) {
    let validation = true;

    const props = Object.keys(input);
    props.forEach((prop) => {
        if (input[prop] === "" || input[prop] === null) validation = false;
    });

    return validation;
}

export function ValidateOptionalData(input, inputType) {
    if (input === "" || input === null) return true;
    else return FinalValidation(input, inputType);
}

function validateAsCep(input) {
    const newInput = input.split("");
    let isValid = true;

    newInput.forEach((char) => {
        if (
            !(
                char === "0" ||
                char === "1" ||
                char === "2" ||
                char === "3" ||
                char === "4" ||
                char === "5" ||
                char === "6" ||
                char === "7" ||
                char === "8" ||
                char === "9" ||
                char === "-"
            )
        ) {
            isValid = false;
        }
    });

    if (newInput.length > 9) isValid = false;

    return isValid;
}

function validateAsCpfOrCnpj(input) {
    const newInput = input.split("");
    let isValid = true;

    newInput.forEach((char) => {
        if (
            !(
                char === "0" ||
                char === "1" ||
                char === "2" ||
                char === "3" ||
                char === "4" ||
                char === "5" ||
                char === "6" ||
                char === "7" ||
                char === "8" ||
                char === "9" ||
                char === "/" ||
                char === "." ||
                char === "-"
            )
        ) {
            isValid = false;
        }
    });

    if (newInput.length > 18) isValid = false;

    return isValid;
}

function validateAsEmail(input) {
    const splitted = input.split("@");

    let validation = false;
    if (splitted.length === 2) {
        const domain = splitted[1].split(".");

        if (domain.length > 1) {
            let validDomain = true;

            domain.forEach((item) => {
                if (item === "") validDomain = false;
            });

            validation = validDomain;
        }
    }

    return validation;
}

function formatAsCep(numbers) {
    let formattedArray = Array.from(numbers);

    if (numbers.length > 5) formattedArray.splice(5, 0, "-");

    const formatted = formattedArray.join("");
    return formatted;
}

function formatAsCpf(numbers) {
    let formattedArray = Array.from(numbers);

    if (numbers.length <= 6 && numbers.length > 3) {
        formattedArray.splice(3, 0, ".");
    }

    if (numbers.length <= 9 && numbers.length > 6) {
        formattedArray.splice(3, 0, ".");
        formattedArray.splice(7, 0, ".");
    }

    if (numbers.length <= 11 && numbers.length > 9) {
        formattedArray.splice(3, 0, ".");
        formattedArray.splice(7, 0, ".");
        formattedArray.splice(11, 0, "-");
    }

    const formatted = formattedArray.join("");
    return formatted;
}

function formatAsCnpj(numbers) {
    let formattedArray = Array.from(numbers);

    if (numbers.length <= 12) {
        formattedArray.splice(2, 0, ".");
        formattedArray.splice(6, 0, ".");
        formattedArray.splice(10, 0, "/");
    }
    if (numbers.length >= 13) {
        formattedArray.splice(2, 0, ".");
        formattedArray.splice(6, 0, ".");
        formattedArray.splice(10, 0, "/");
        formattedArray.splice(15, 0, "-");
    }

    const formatted = formattedArray.join("");
    return formatted;
}

function getNumbers(str) {
    const numbers = str.split("").filter((char) => !isNaN(parseInt(char, 10)));

    return numbers;
}
