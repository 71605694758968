import React from "react";
import styled from "styled-components";

import UserArea from "../login/UserArea";
import Colors from "../commons/Colors";

export default function Header() {
    return (
        <Container>
            <UserButton />
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px;
    padding-left: 32px;
    padding-right: 32px;
    box-shadow: rgb(199 199 204) 0px 1px 4px 0px;
`;

const UserButton = styled(UserArea)`
    margin-left: 20px;
`;
