const ONE_DAY = 1000 * 60 * 60 * 24;
const ONE_HOUR = 1000 * 60 * 60;

export function dateFormat(date) {
    if (!date) return "";

    if (typeof date === "string") date = new Date(date);

    const day = date.getDate().toString().padStart(2, "0"),
        month = (date.getMonth() + 1).toString().padStart(2, "0"),
        year = date.getFullYear();

    return `${day}/${month}/${year}`;
}
