import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Table, Button, Tooltip } from "antd";
import { EyeOutlined, CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Redirect } from "react-router";

import * as SydleAPI from "../commons/SydleAPI";
import Colors from "../commons/Colors";
import Header from "./Header";
import RegistrationModal from "./RegistrationModal";

export default function DashboardScreen() {
    const [registrations, setRegistrations] = useState([]);
    const [tableDisplayData, setTableDisplayData] = useState([]);
    const [registration, setRegistration] = useState(null);
    const [loading, setLoading] = useState(true);
    const [redirectToFormCreation, setRedirectToFormCreation] = useState(false);
    const [isRegistrationModalVisible, setIsRegistrationModalVisible] =
        useState(false);
    const [filter, setFilter] = useState("");

    const tableColumns = [
        {
            title: "Consultor",
            dataIndex: "consultantName",
            key: "consultantName",
        },
        {
            title: "Cadastro",
            dataIndex: "selectedForm",
            key: "selectedForm",
        },
        {
            title: "Produto",
            dataIndex: "selectedProduct",
            key: "selectedProduct",
        },
        {
            title: "Razão Social",
            dataIndex: "socialReason",
            key: "socialReason",
        },
        {
            title: "CPF / CNPJ",
            dataIndex: "cpfOrCnpj",
            key: "cpfOrCnpj",
        },
        {
            title: "",
            dataIndex: "actions",
            key: "actions",
            render: (text, record) => getActions(record.key),
        },
    ];

    const getActions = (formID) => {
        return (
            <Tooltip title="Visualizar" placement="left">
                <ActionButton
                    shape="circle"
                    icon={<EyeOutlined />}
                    onClick={async () => {
                        SydleAPI.getForm(formID, "tech")
                            .then((response) => {
                                setRegistration(response.form);
                                setIsRegistrationModalVisible(true);
                            })
                            .catch((error) => {
                                console.error("Erro: " + JSON.stringify(error));
                                throw error;
                            });
                    }}
                />
            </Tooltip>
        );
    };

    useEffect(() => {
        SydleAPI.getTechnologyForms()
            .then((response) => {
                response.forms.sort(
                    (a, b) =>
                        new Date(b._creationDate) - new Date(a._creationDate)
                );
                const items = response.forms.map((form) => {
                    return {
                        key: form._id,
                        selectedForm: form.startInfo.selectedForm || "",
                        selectedProduct: form.selectedProduct || "",
                        socialReason: form.socialReason || "",
                        cpfOrCnpj: form.cpfOrCnpj || "",
                        consultantName: form.consultantName || "",
                    };
                });

                setRegistrations(items);
                setTableDisplayData(items);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleFilterOnChange = (value) => {
        setFilter(value);

        setTableDisplayData(() => {
            return registrations.filter(
                (registration) =>
                    registration.consultantName.toUpperCase().includes(value) ||
                    registration.selectedProduct
                        .toUpperCase()
                        .includes(value) ||
                    registration.socialReason.toUpperCase().includes(value) ||
                    registration.cpfOrCnpj.toUpperCase().includes(value)
            );
        });
    };

    return (
        <>
            {redirectToFormCreation && <Redirect to="/start" />}
            <RegistrationModal
                visible={isRegistrationModalVisible}
                setVisible={setIsRegistrationModalVisible}
                registration={registration}
            />

            <Header />

            <Container>
                <FilterContainer>
                    <SearchIcon />
                    <Input
                        placeholder="Pesquise por Consultor, Produto, Razão Social ou CPF / CNPJ"
                        value={filter}
                        onChange={(e) =>
                            handleFilterOnChange(e.target.value.toUpperCase())
                        }
                    />
                    {filter !== "" && (
                        <CloseIcon onClick={() => handleFilterOnChange("")} />
                    )}
                </FilterContainer>
                <TableContainer>
                    <TableHeader>
                        <Title>LISTAGEM DE CADASTROS</Title>
                        <NewFormButton
                            onClick={() => setRedirectToFormCreation(true)}
                        >
                            CRIAR CADASTRO
                        </NewFormButton>
                    </TableHeader>
                    <RegistrationsTable
                        dataSource={tableDisplayData}
                        columns={tableColumns}
                        loading={loading}
                        pagination={{
                            position: ["none", "bottomLeft"],
                        }}
                    />
                </TableContainer>
            </Container>
        </>
    );
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 32px;
`;

const Title = styled.h1`
    font-size: 24px;

    padding-left: 5px;
    padding-right: 5px;
    margin: 0px;

    color: ${Colors.darkBlue};
    white-space: nowrap;
`;

const TableContainer = styled.div`
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 800px) {
        padding: 0px;
    }
`;

const TableHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @media (max-width: 650px) {
        flex-direction: column;
        position: relative;
        justify-content: center;
    }
`;

const RegistrationsTable = styled(Table)`
    white-space: nowrap;

    @media (max-width: 1028px) {
        display: inline-table;
    }

    thead {
        .ant-table-cell {
            background-color: ${Colors.veryLightPink} !important;
        }

        th {
            color: ${Colors.brownishGrey};
            font-weight: 700;
        }
    }

    tbody {
        .ant-table-cell {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }
`;

const NewFormButton = styled(Button)`
    outline: none;
    border: none;
    border-radius: 15px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    color: ${Colors.white};
    background-color: ${Colors.leafyGreen};
    margin-right: 20px;
    margin-left: auto;

    &:hover {
        cursor: pointer;
        background-color: ${Colors.bluishGreen};
        color: ${Colors.white};
    }

    &:focus {
        background-color: ${Colors.bluishGreen};
        color: ${Colors.white};
    }

    @media (max-width: 650px) {
        margin: 0px;
    }
`;

const ActionButton = styled(Button)`
    border: none;
    box-shadow: none;

    &:focus {
        outline: none;
    }
`;

const Input = styled.input`
    border: none;
    background: none;
    width: 400px;

    &:focus {
        outline: none;
    }
`;

const FilterContainer = styled.div`
    margin: auto;
    padding: 5px;
    border: 1px solid ${Colors.grey};
    border-radius: 5px;
`;

const CloseIcon = styled(CloseOutlined)`
    margin-left: 10px;
    color: ${Colors.brownishGrey};
    font-size: 12px;
    padding: 5px;

    &:hover {
        cursor: pointer;
    }
`;

const SearchIcon = styled(SearchOutlined)`
    margin-right: 10px;
    color: ${Colors.brownishGrey};
`;
