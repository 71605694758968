import React from "react";
import { Modal, Button } from "antd";
import styled from "styled-components";

import Colors from "../commons/Colors";
import { dateFormat } from "../commons/DateUtils";

export default function RegistrationModal({
    visible = false,
    setVisible,
    registration,
}) {
    const handleOk = () => {
        setVisible(false);
    };

    return (
        registration && (
            <ModalContainer
                onOk={handleOk}
                visible={visible}
                closable={false}
                title={`${registration.startInfo.selectedForm} - ${registration.socialReason}`}
                footer={[
                    <FooterButton
                        key="close"
                        onClick={() => setVisible(false)}
                        type="primary"
                    >
                        Fechar
                    </FooterButton>,
                ]}
                width="700px"
            >
                <Section>
                    <SectionTitle>Dados Cadastrais</SectionTitle>
                    <SectionContainer>
                        <SectionItem>
                            <ItemLabel>TIPO DE CADASTRO</ItemLabel>
                            <Item>
                                {registration.startInfo.selectedForm || "-"}
                            </Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>PRODUTO ESCOLHIDO</ItemLabel>
                            <Item>{registration.selectedProduct || "-"}</Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>RAZÃO SOCIAL</ItemLabel>
                            <Item>{registration.socialReason || "-"}</Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>NOME DA FAZENDA</ItemLabel>
                            <Item>{registration.propertyName || "-"}</Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>CPF / CNPJ</ItemLabel>
                            <Item>{registration.cpfOrCnpj || "-"}</Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>INSCRIÇÃO ESTADUAL</ItemLabel>
                            <Item>{registration.stateRegistration || "-"}</Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>DATA DE CRIAÇÃO</ItemLabel>
                            <Item>
                                {dateFormat(registration._creationDate)}
                            </Item>
                        </SectionItem>
                    </SectionContainer>
                </Section>

                <Section>
                    <SectionTitle>Informações De Contato</SectionTitle>
                    <SectionContainer>
                        <SectionItem>
                            <ItemLabel>E-MAIL</ItemLabel>
                            <Item>
                                {registration.startInfo.contactEmail || "-"}
                            </Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>Nº TELEFONE</ItemLabel>
                            <Item>{registration.phoneNumber || "-"}</Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>Nº WHATSAPP</ItemLabel>
                            <Item>{registration.whatsAppNumber || "-"}</Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>OUTROS</ItemLabel>
                            <Item>{registration.otherPhoneNumber || "-"}</Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>E-MAIL NF</ItemLabel>
                            <Item>{registration.emailNF || "-"}</Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>E-MAIL BOLETO</ItemLabel>
                            <Item>{registration.emailBOL || "-"}</Item>
                        </SectionItem>
                    </SectionContainer>
                </Section>

                <Section>
                    <SectionTitle>Endereço De Cobrança</SectionTitle>
                    <SectionContainer>
                        <SectionItem>
                            <ItemLabel>RUA</ItemLabel>
                            <Item>{registration.addressNF.street || "-"}</Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>NÚMERO</ItemLabel>
                            <Item>{registration.addressNF.number || "-"}</Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>BAIRRO</ItemLabel>
                            <Item>
                                {registration.addressNF.neighborhood || "-"}
                            </Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>CIDADE</ItemLabel>
                            <Item>{registration.addressNF.city || "-"}</Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>ESTADO</ItemLabel>
                            <Item>{registration.addressNF.state || "-"}</Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>CEP</ItemLabel>
                            <Item>{registration.addressNF.cep || "-"}</Item>
                        </SectionItem>
                    </SectionContainer>
                </Section>

                <Section>
                    <SectionTitle>Outros</SectionTitle>
                    <SectionContainer>
                        <SectionItem>
                            <ItemLabel>CONSULTOR</ItemLabel>
                            <Item>{registration.consultantName || "-"}</Item>
                        </SectionItem>
                        <SectionItem>
                            <ItemLabel>OBSERVAÇÕES</ItemLabel>
                            <Item>{registration.notes || "-"}</Item>
                        </SectionItem>
                    </SectionContainer>
                </Section>
            </ModalContainer>
        )
    );
}

const FooterButton = styled(Button)`
    outline: none;
    border: none;
    border-radius: 15px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    color: ${Colors.white};
    background-color: ${Colors.leafyGreen};

    &:hover {
        cursor: pointer;
        background-color: ${Colors.bluishGreen};
        color: ${Colors.white};
    }

    &:focus {
        background-color: ${Colors.bluishGreen};
        color: ${Colors.white};
    }
`;

const ModalContainer = styled(Modal)`
    .ant-modal-body {
        background-color: ${Colors.veryLightPink};
        padding: 0px;
    }

    .ant-modal-title {
        color: ${Colors.black};
    }

    .ant-modal-header {
        background-color: ${Colors.hospitalGreen};
    }
`;

const Section = styled.div`
    background-color: ${Colors.white};
    padding: 10px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 10px;
`;

const SectionTitle = styled.h2`
    font-size: 14px;
    font-weight: bold;
    color: ${Colors.black};
`;

const SectionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`;

const SectionItem = styled.div``;

const ItemLabel = styled.label`
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
    text-transform: uppercase;
    color: ${Colors.brownishGrey};
`;

const Item = styled.p`
    font-size: 13px;
    color: ${Colors.black};
    line-height: 1.43;
    letter-spacing: 0.25px;
`;
