import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./App.css";

import FormStart from "./form/FormStart.js";
import FormSTP from "./form/FormSTP.js";
import FormTechnology from "./form/FormTechnology.js";
import LoginPage from "./login/LoginPage";
import PrivateRoute from "./login/PrivateRoute";
import DashboardScreen from "./dashboard/DashboardScreen";

function App() {
    const [contactEmail, setContactEmail] = useState("");
    const [selectedForm, setSelectedForm] = useState("");

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/TechnologyForm">
                    <PrivateRoute
                        render={(props) => (
                            <FormTechnology
                                {...props}
                                startInfo={{
                                    contactEmail: contactEmail,
                                    selectedForm: selectedForm,
                                }}
                            />
                        )}
                    />
                </Route>

                {/* <Route exact path="/STPForm">
                    <PrivateRoute
                        render={(props) => (
                            <FormSTP
                                {...props}
                                startInfo={{
                                    contactEmail: contactEmail,
                                    selectedForm: selectedForm,
                                }}
                            />
                        )}
                    />
                </Route> */}

                <Route exact path="/start">
                    <PrivateRoute
                        render={(props) => (
                            <FormStart
                                {...props}
                                contactEmail={contactEmail}
                                setContactEmail={setContactEmail}
                                selectedForm={selectedForm}
                                setSelectedForm={setSelectedForm}
                            />
                        )}
                    />
                </Route>

                <Route path="/login" exact render={() => <LoginPage />} />

                <Route exact path="/">
                    <PrivateRoute render={(props) => <DashboardScreen />} />
                </Route>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
