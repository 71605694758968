import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { WarningOutlined } from "@ant-design/icons";

import Colors from "../../commons/Colors.js";

export default function Input({
    name,
    form,
    title,
    inputType = "text",
    description,
    placeholder,
    values = [],
    required = false,
    valid = true,
    uppercase = false,
    setValidation,
    setFormData,
}) {
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        setFormData((actualState) => {
            let updated = Object.assign(actualState);
            updated[name] = inputValue;
            return updated;
        });
    });

    const handleInputType = (type) => {
        if (type === "text") {
            return (
                <TextInput
                    type="text"
                    placeholder={placeholder}
                    form={form}
                    id={name}
                    name={name}
                    value={inputValue}
                    onChange={(event) =>
                        uppercase
                            ? setInputValue(event.target.value.toUpperCase())
                            : setInputValue(event.target.value)
                    }
                />
            );
        }

        if (type === "dropDown") {
            return (
                <DropDown
                    name={name}
                    id={name}
                    value={inputValue}
                    onChange={(event) => setInputValue(event.target.value)}
                >
                    <DropDownPlaceHolder value="" key="">
                        (vazio)
                    </DropDownPlaceHolder>
                    {values.map((value) => (
                        <option key={value} value={value}>
                            {value}
                        </option>
                    ))}
                </DropDown>
            );
        }
    };

    const renderInput = () => {
        return (
            <InputContainer>
                <h3>{title}</h3>
                <p>{description ? description : null}</p>
                <InputField>
                    {handleInputType(inputType)}
                    {required ? <Required>*</Required> : null}
                </InputField>
            </InputContainer>
        );
    };

    const renderInvalid = () => {
        return (
            <Invalid>
                <WarningOutlined style={{ color: "white" }} />
                <InvalidText>
                    O campo &quot;{title}&quot; é obrigatório
                </InvalidText>
            </Invalid>
        );
    };

    return (
        <div>
            <Container onFocus={() => (required ? setValidation(true) : null)}>
                {renderInput()}
                {!valid ? renderInvalid() : null}
            </Container>
        </div>
    );
}

const DropDownPlaceHolder = styled.option`
    color: grey;
`;
const Invalid = styled.div`
    background-color: ${Colors.strawberry};
    margin-top: 10px;
    padding-left: 20px;
    border-radius: 0px 0px 8px 8px;

    @keyframes slide {
        from {
            height: 0px;
            font-size: 50%;
        }
        to {
            height: 40px;
            font-size: 100%;
        }
    }

    display: flex;
    align-items: center;

    animation-name: slide;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
`;

const InvalidText = styled.p`
    color: white;
    margin: 0px;
    margin-left: 10px;

    @media (max-width: 860px) {
        font-size: 90%;
    }
`;

const InputContainer = styled.div`
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
`;

const Container = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;

    border: 0px;
    border-radius: 8px;

    margin-bottom: 12px;
    align-content: stretch;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
`;

const InputField = styled.div`
    display: flex;
    flex-direction: row;
`;

const TextInput = styled.input`
    padding: 5px;
    padding-top: 10px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color: ${Colors.lightBlue};

    outline: none;

    width: 30%;

    @media (max-width: 860px) {
        width: 70%;
    }
`;

const Required = styled.p`
    color: #f00;
    margin: 0px;
    padding-left: 10px;
`;

const DropDown = styled.select`
    border-radius: 5px;
    padding: 5px;
    outline: none;
    border: 1px solid #dadce0;
`;
