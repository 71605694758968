import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input, Button, message } from "antd";
import { UserOutlined, KeyOutlined } from "@ant-design/icons";
import { Redirect } from "react-router";

import * as SydleAPI from "../commons/SydleAPI";
import Colors from "../commons/Colors";

export default function LoginPage() {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [authenticating, setAuthenticating] = useState(false);
    const [invalidCredentials, setInvalidCredentials] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);

    const handleSubmit = () => {
        if (userName === "" || password === "") {
            setInvalidCredentials(true);
            message.error("Insira seu Usuário e Senha corretamente.");
            return;
        }

        signIn(userName, password, setAuthenticating);
    };

    const signIn = async (username, password, setAuthenticating) => {
        setAuthenticating(true);
        try {
            const response = await SydleAPI.signIn(username, password);

            if (response.accessToken) {
                localStorage.setItem(
                    "contractsToken",
                    JSON.stringify(response)
                );
                message.success(
                    "Login Efetuado com sucesso! Redirecionando..."
                );

                setTimeout(() => setAuthenticated(true), 1000);
            } else {
                message.error("Usuário e/ou Senha Incorretos.");
                setInvalidCredentials(true);
                setAuthenticating(false);
            }
        } catch (error) {
            console.error(error.message);
            message.error(
                "Houve um problema com o login, verifique suas credenciais!"
            );
            setAuthenticating(false);
        }
    };

    return (
        <Container>
            {authenticated ? <Redirect to="/" /> : null}
            <LoginContainer>
                <UserInput
                    placeholder="Usuário"
                    prefix={<UserOutlined />}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    style={{
                        borderColor: invalidCredentials && Colors.strawberry,
                    }}
                    onFocus={() => setInvalidCredentials(false)}
                />
                <PasswordInput
                    placeholder="Senha"
                    prefix={<KeyOutlined />}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{
                        borderColor: invalidCredentials && Colors.strawberry,
                    }}
                    onFocus={() => setInvalidCredentials(false)}
                />

                <LoginButton loading={authenticating} onClick={handleSubmit}>
                    Entrar
                </LoginButton>
            </LoginContainer>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100vh;

    @media (max-width: 860px) {
        padding-left: 1.5%;
        padding-right: 1.5%;
    }

    background-color: ${Colors.darkBlue};
`;

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;

    border-radius: 5px;
    background-color: ${Colors.white};
    padding: 50px;
`;

const UserInput = styled(Input)`
    margin-bottom: 10px;
`;

const PasswordInput = styled(Input.Password)`
    margin-bottom: 10px;
`;

const LoginButton = styled(Button)`
    outline: none;
    border: none;
    border-radius: 15px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    color: ${Colors.white};
    background-color: ${Colors.leafyGreen};
    margin-left: auto;

    &:hover {
        cursor: pointer;
        background-color: ${Colors.bluishGreen};
        color: ${Colors.white};
    }

    &:focus {
        background-color: ${Colors.bluishGreen};
        color: ${Colors.white};
    }
`;
